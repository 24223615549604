.world_standard {
  margin-top: 3rem;
}
.world_standard > h3 {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  letter-spacing: 0.1rem;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
}
.world_standard > div {
  display: flex;
}
.world_standard > div > img {
  width: 65%;
}
.world_standard > div > p {
  padding-left: 1.5rem;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

@media (max-width: 768px) {
  .world_standard > div {
    flex-direction: column;
  }
  .world_standard > div > img {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .world_standard > div > p {
    padding-left: 0rem;
  }
}
