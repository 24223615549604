/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/



.adminCategoryShowWrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.adminCategoryCard {
  margin-top: 2rem;
}

.adminCategoryMainTitle {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
}

.adminCategoryTitle{
  font-size: 1rem;
}

.adminCategoryForm{
  width: 100%;
}

.adminCategoryAddFieldDiv {
  width: 100%;
}

.adminCategoryInput {
  border: 1px solid #211c24;
}

.adminCategoryInput:hover {
  border: 1px solid #211c24;
}

.adminCategoryInput:focus {
  border: 1px solid #211c24;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.adminCategoryInput::-webkit-input-placeholder {
  color: #211c24;
}

.adminCategoryInput::-moz-placeholder {
  color: #211c24;
}

.adminCategoryInput:-ms-input-placeholder {
  color: #211c24;
}

.adminCategoryInput::-ms-input-placeholder {
  color: #211c24;
}

.adminCategoryInput::placeholder {
  color: #211c24;
}

.adminCategoryButton {
  background-color: #211c24 !important;
  border-color: #211c24 !important;
}
.adminCategoryButton:hover {
  background-color: #211c24 !important;
  border-color: #211c24 !important;
  opacity: 0.95;
}
.adminCategoryButton:focus {
  background-color: #211c24 !important;
  border-color: #211c24 !important;
}