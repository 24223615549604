/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.headerNavWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.headerNavInnerWrapper {
  display: flex;
  align-items: center;
  z-index: 3;
}

.headerLangSelect {
  width: 5rem;
  margin-left: 1rem;
}

.headerLangSelect > div {
  background-color: #211c24 !important;
}

.headerNavLink {
  font-size: 1rem;
  padding: 0.5rem;
  color: #f6f6f6;
}

.headerActiveNavLink {
  font-size: 1rem;
  padding: 0.5rem;
  color: #0b9dde;
}

.ant-select-arrow .anticon > svg {
  fill: white;
}

@media (max-width: 1200px) {
  .headerNavWrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

@media (max-width: 992px) {
  .headerNavWrapper {
    justify-content: center;
  }

  .header_close_and_title_wrapper {
    margin-bottom: 3rem;
  }

  .header_nav_title {
    color: rgba(255, 255, 255, 0.9);
    font-size: 2rem;
    font-weight: 500;
  }

  .burger_close {
    position: absolute;
    right: 1.5rem;
    top: 2.7rem;
  }

  .headerNavInnerWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
.headerNavLink {
  font-size: 1.5rem; 
}

.headerActiveNavLink {
  font-size: 1.5rem;
}
}
@media (max-width: 768px) {
  .headerNavWrapper {
    justify-content: center;
  }

  .header_close_and_title_wrapper {
    margin-bottom: 3rem;
  }

  .header_nav_title {
    color: rgba(255, 255, 255, 0.9);
    font-size: 2rem;
    font-weight: 500;
  }

  .burger_close {
    position: absolute;
    right: 1.5rem;
    top: 2.7rem;
  }

  .headerNavInnerWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
