.analytics_chart_container {
  display: flex;
  margin-top: 3rem;
  height: 100%;
  width: 100%;
}

.pie_chart {
  flex: 1;
}

.chart_card_wrapper {
  flex: 1;
}

.chart_card_wrapper > div {
  padding-right: 1rem;
  overflow: auto;
  max-height: 30rem;
  overflow-x: hidden;
  overflow-wrap: break-word;
  display: grid;
  grid-template-columns: auto auto auto;
}

.analytics_chart_city_card {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  letter-spacing: 0.1rem;
  min-height: 5rem;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 0.5rem;
  border: 1px solid black;
}

.analytics_chart_city_card > h3 {
  margin: 0;
}
.analytics_chart_city_card > h2 {
  margin: 0;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
}

@media (max-width: 768px) {
  .analytics_chart_container {
    flex-direction: column;
    margin-top: 0;
  }
  .analytics_chart_city_card {
    margin: 0.1rem;
  }
  .chart_card_wrapper > div {
    padding-right: 0rem;
    overflow: auto;
    max-height: 20rem;
    overflow-x: hidden;
    overflow-wrap: break-word;
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
