/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.adminCard {
  margin-top: 3rem;
  padding-top: 2rem;
}

.admin_tabs {
  margin-top: 1rem;
}

@media (max-width: 768px) {
   .ant-tabs-nav-list {
    margin-left: 1rem;
   }
}
