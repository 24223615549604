/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.footerWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #211c24;
  width: 100%;
  margin-top: 4rem;
  bottom: 0;
}

.footerCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerCard {
  background-color: #211c24;
  border-radius: 1rem;
  width: 25rem;
  padding: 0.3rem;
  margin: 2rem 2rem 0rem 2rem;
  -webkit-transition: scale 0.3s ease;
  -o-transition: scale 0.3s ease;
  transition: scale 0.3s ease;
  border: 1px solid white !important;
}

.footerCardInnerWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footerCard:hover {
  scale: 1.02;
  -webkit-box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;

  cursor: pointer;
}

.footerCardInnerWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footerCardInnerInfo {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.footerTitle {
  text-align: center;
  font-weight: bold;
  font-size: 4.5rem;
  color: #f6f6f6;
}

.footerBy {
  text-align: center;
  margin-bottom: 0.7rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: #f6f6f6;
}

.socialMediaWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 2rem 0rem;
}

.socialMediaLogo2 {
  margin: 0 1rem;
}

.socialMediaLogo1:hover,
.socialMediaLogo2:hover {
  cursor: pointer;
}

@media (max-width: 992px) {
  .footerCardContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .footerCardContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footerTitle {
    font-size: 3rem;
    padding-top: 1rem;
  }

  .footerCard {
    background-color: #211c24;
    border-radius: 1rem;
    width: 20rem;
  }
  .socialMediaLogo2 {
    margin: 0rem 3rem;
  }
}
