/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.adminCard {
  margin-top: 3rem;
  padding-top: 2rem;
}

.adminFormInput:hover{
  border-color: #211c24 !important;
}
.adminFormInput:focus{
  border-color: #211c24 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.ant-input-affix-wrapper-focused {
  border-color: #211c24 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;

}

 .adminFormButton{
  background-color: #211c24;
  border-color: #211c24;
 }

 .adminFormButton:hover{
  opacity: 0.96;
  background-color: #211c24;
  border-color: #211c24;
 }

 .adminFormButton:active{
  background-color: #211c24;
  border-color: #211c24;
 }

 .adminFormButton:focus{
  background-color: #211c24;
  border-color: #211c24;
 }