.carousel {
  height: 35rem !important;
  width: 100%;
  margin-top: 3rem;
}

.carousel_image {
  width: 100%;
  height: 35rem;
}
.carousel__icon {
  cursor: pointer;
}
@media (max-width: 768px) {
  .carousel {
    height: 25rem !important;
    width: 100%;
    margin-top: 3rem;
  }

  .carousel_image {
    width: 100%;
    height: 25rem;
  }
}
