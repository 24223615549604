.about_title {
  text-align: center;
  font-size: 2.7rem;
  margin: 2.1rem 0;
  color: rgba(0, 0, 0, 0.9);
}

.about_section {
  display: flex;
  margin-bottom: 3rem;
}

.about_section > img {
  width: 35rem;
  border-radius: 1rem;
}

.about_section > div {
  padding-left: 3rem;
  color: rgba(0, 0, 0, 0.9);
}
.about_section > div > h3 {
  letter-spacing: 0.1rem;
  text-align: center;
  font-weight: 600;
}
.about_section > div > p {
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.about_section_reverse {
  flex-direction: row-reverse;
}
.about_section_reverse > div {
  padding-left: 0rem;
  padding-right: 2rem;
}

@media (max-width: 992px) {
  .about_section {
    flex-direction: column;
    align-items: center;
  }
  .about_section > img {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .about_title {
    font-size: 2rem;
  }
  .about_section {
    flex-direction: column;
    margin-bottom: 2.1rem;
  }

  .about_section > img {
    width: 100%;
  }
  .about_section > div {
    padding-left: 0;
    margin-top: 1.5rem;
  }
  .about_section_reverse > div {
    padding-right: 0rem;
  }
}
