/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.dashboardAddCategory {
  margin-bottom: 1rem;
}

.dashboardCard {
  margin-top: 1.5rem;
  margin-bottom: 2.1rem;
}

.eklenilenAlanlar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.eklenilenAlanlarWrapper {
  border-top: 1px solid #211c24;
  border-bottom: 1px solid #211c24;
  padding: 1.5rem;
}

.eklenilenAlanBaslık {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
}

.eklenilenAlanDeger {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.dashboardBorder {
  border-top: 1px solid #211c24;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.dashboardButton {
  background-color: #211c24;
  border-color: #211c24;
  color: #f6f6f6;
}

.dashboardButton:hover {
  background-color: #211c24;
  border-color: #211c24;
  opacity: 0.9;
  color: #f6f6f6;
}

.dashboardButton:focus {
  background-color: #211c24;
  border-color: #211c24;
  opacity: 0.9;
  color: #f6f6f6;
}
.dashboardButton:active {
  background-color: #211c24;
  border-color: #211c24;
  opacity: 0.9;
  color: #f6f6f6;
}

.sendButton {
  align-tracks: center;
  font-size: 1.2rem;
  height: 3rem;
}

.dashboardItem {
  border-color: #211c24 !important;
  outline-color: none !important;
}

.dashboardItem:focus {
  border-color: #211c24;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dashboardItem:hover {
  border-color: #211c24;
  outline-color: #211c24;
}

.dashboardDragger {
  border-color: #211c24 !important;
}

.dashboardDragger:hover {
  border-color: #211c24 !important;
}

.ant-selector {
  background-color: red !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #211c24 !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dashboardItem::-webkit-input-placeholder {
  color: #211c24;
}

.dashboardItem::-moz-placeholder {
  color: #211c24;
}

.dashboardItem:-ms-input-placeholder {
  color: #211c24;
}

.dashboardItem::-ms-input-placeholder {
  color: #211c24;
}

.dashboardItem::placeholder {
  color: #211c24;
}

.ant-select-selection-placeholder {
  border-color: #211c24 !important;
  color: #211c24;
}

.dashboardNoInputsText {
  margin-top: 1rem;
  font-size: 1rem;
}
