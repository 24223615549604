.who_we_are {
  padding-top: 2.5rem;
}
.who_we_are > div {
  letter-spacing: 0.1rem;
}
.who_we_are > div > h3 {
  text-align: center;
  font-size: 2rem;
}
.who_we_are > div > p {
  font-weight: 500;
}

.who_we_are_img_icons_wrapper {
  display: flex;
  margin-top: 2rem;
}
.who_we_are_img_icons_wrapper > img {
  width: 80%;
  flex: 3;
  border-radius: 0.3rem;
}

.who_we_are_img_icons_wrapper > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 2.3rem;
}

.who_svg {
  font-size: 3rem;
  transition: all 0.3s ease;
}
.who_svg:hover {
  cursor: pointer;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .who_we_are > div > p {
    text-align: center;
  }
  .who_we_are_img_icons_wrapper {
    flex-direction: column;
  }
  .who_we_are_img_icons_wrapper > img {
    width: 100%;
  }
  .who_we_are_img_icons_wrapper > div {
    flex-direction: row;
    padding: 0 0.5rem;
    padding-top: 1rem;
  }
}
