.hero {
  width: 100%;
  height: 100vh;
  background-image: url("../../public/images/background/hero-bg.jpg");
  background-size: cover;
}
.hero > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 9%;
  letter-spacing: 0.3rem;
}
.hero > div > h3 {
  font-size: 5rem;
  color: rgba(255, 255, 255, 0.8);
}
.hero > div > h4 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 2rem;
  letter-spacing: 1rem;
}

@media (max-width: 992px) {
  .hero {
    background-position-x: center;
  }

  .hero > div {
    padding-top: 15%;
  }
  .hero > div > h3 {
    font-size: 3rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
  }
  .hero > div > h4 {
    font-size: 2rem;
    letter-spacing: 0.3rem;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .hero {
    background-position-x: center;
  }

  .hero > div {
    align-items: center;
    padding-top: 50%;
  }
  .hero > div > h3 {
    font-size: 2rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.9);
  }
  .hero > div > h4 {
    font-size: 1rem;
    letter-spacing: 0.3rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
  }
}
