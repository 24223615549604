/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.dashboardFormTitle{
  margin-bottom: 1rem;
}

.dashboardFormInput{
  margin-bottom: 1rem;
  border-color: #211c24;
}
.dashboardFormInput:hover{
  border-color: #211c24;
}
.dashboardFormInput:focus{
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #211c24;
}

.dashboardFormInput::-webkit-input-placeholder{
  color: #211c24 !important;
}

.dashboardFormInput::-moz-placeholder{
  color: #211c24 !important;
}

.dashboardFormInput:-ms-input-placeholder{
  color: #211c24 !important;
}

.dashboardFormInput::-ms-input-placeholder{
  color: #211c24 !important;
}

.dashboardFormInput::placeholder{
  color: #211c24 !important;
}