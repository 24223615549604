/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.iletisimCard {
  padding: 0 0rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.iletisimTitle {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 1.3rem;
}

.infoWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1rem;
  text-align: justify;
}

.iletisimColumnBorder {
  border-right: 1px solid #211c24;
}

.iletisimInfoTitle {
  font-weight: 600;
  font-size: 1rem;
}

.iletisimInfoSubtitle {
  font-weight: 600;
  margin-top: 1rem;
  font-size: 1rem;
}

.iletisimInfoText {
  font-size: 0.9rem;
}

.ant-card-bordered {
  border: 1px solid #211c24;
}

.iletisimIframe {
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 2rem;
}

.instagramWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.instagramWrapper > div:hover {
  cursor: pointer;
}

.instagramWrapper > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.instagramWrapper > div > div {
  font-size: 1rem;
}

.iletisimFacebookIcon {
  margin-top: 1rem;
}

@media (max-width: 992px) {
  .iletisimColumnBorder {
    border: none;
  }
}

@media (max-width: 768px) {
  .iletisimCard {
    padding: 0;
  }

  .infoWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .iletisimInfoTitle {
    padding-top: 1rem;
  }

  .iletisimInfoText {
    padding-bottom: 1rem;
  }

  .instagramWrapper {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-bottom: 1rem;
  }

  .iletisimFacebookIcon {
    margin-left: 1rem;
    margin-top: 0rem;
  }

  .iletisimEmailBorder {
    border-top: 1px solid #211c24;
    padding-bottom: 0.5rem;
  }
}
