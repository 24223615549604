.video_container {
  margin-top: 3rem;
}

.video {
  border-radius: 0.3rem;
}

@media (max-width: 768px) {
  .video {
    height: 25rem;
    object-fit: initial;
  }
}
