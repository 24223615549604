/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.ürünlerimizMainTitle {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}


.urunlerimizBreadcrumb{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.urunlerimizUrunlerWrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: red;
  
}


 @media (max-width: 1200px) {
  .urunlerimizListCol {
    margin-bottom: 1rem;
  }
} 