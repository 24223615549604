.analytics_contanier {
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}

.analytics_top_info {
  display: flex;
  justify-content: space-between;
}

.analytics_top_info > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  padding: 1rem;
  min-width: 17rem !important;
  border-radius: 0.5rem;
  letter-spacing: 0.1rem;
  text-align: center;
}

.analytics_top_info > div > h3 {
  margin-bottom: 1rem;
}

.analytics_chart_info {
  border: 1px solid black;
  padding: 3rem 3rem;
  border-radius: 0.5rem;
  margin-top: 3rem;
  max-height: 45rem;
}

.analytics_chart_info > h3 {
  text-align: center;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .analytics_top_info {
    flex-direction: column;
  }
  .analytics_chart_info {
    padding: 1rem 0;
  }
  .analytics_chart_info > h3 {
    margin-bottom: 0rem !important;
  }
  .analytics_card_middle {
    margin: 1rem 0;
  }
}
