/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.treeItem {
  font-size: 2rem !important;
  padding: 0.5rem !important;
}

.treeItem div {
  font-size: 1.1rem !important;
}

.treeview {
  height: auto;
  width: 100% !important;
  padding-top: 1rem !important;
  padding-bottom: 4rem !important;
}

.treeDiv {
  font-family: "Oswald", sans-serif !important;
}
