/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.listUrunImg {
  width: 100%;
  height: 350px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom: 1px solid #211c24;
}

.listUrunTitle {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.7rem 0;
  text-align: center;
}

.listUrunCol {
  margin-top: 2rem;
  width: 100%; 
}

.ürünlerimizCard {
  border-radius: 1rem;
  width: 100%;

  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ürünlerimizCard > div {
  padding: 0 !important;
}

.ürünlerimizCard:hover {
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  cursor: pointer;
}
