/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

label {
  font-size: 16px !important;
}

.customForm {
  width: 100%;
  margin-right: 1rem;
}

.formInput {
  border: 1px solid #211c24;
  -webkit-box-shadow: 10px red;
  box-shadow: 10px red;
}

.formInput:hover {
  border: 1px solid #211c24;
}
.formInput:focus {
  border: 1px solid #211c24 !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.formSubmitButton {
  width: 10rem;
  height: 3rem;
  border-radius: 1rem;
  color: #f6f6f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  background-color: #211c24;
  border-color: #211c24;
  margin-top: 1rem;
}

.formSubmitButton:hover {
  opacity: 1.2;
  background-color: #211c24;
  border-color: #211c24;
}

.formSubmitButton:active {
  opacity: 0.9;
  background-color: #211c24;
  border-color: #211c24;
}

.formSubmitButton:focus {
  background-color: #211c24;
  border-color: #211c24;
}

.ant-form-item-label label {
  font-size: 1.2rem;
  font-weight: 500;
}
