/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.urunDetayInfoWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 4rem;
  margin-right: 1rem;
}

.urunDetayImg {
  width: 100%;
  height: 600px;
  border-radius: 1rem;
}

.urunDetayTableWrapper {
  width: 100%;
}

.urunDetayTable {
  width: 100% !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.carouselWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 3rem;
}

.urunDetayCarousel {
  margin: 0 1rem;
  width: 30rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  border-radius: 1rem;
}

.urunDetayUrunSilButton {
  margin-top: 1.5rem;
  margin-right: 1.5rem;
}

.urunDetayRelatedWrapper {
  margin-top: 7rem;
}

.urunDetayRelatedTitle {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 1.3rem;
}

.urunDetayRelatedProducts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.urunDetayTableTitle {
  font-weight: bold;
  font-size: 1.9rem;
  margin-bottom: 1.2rem;
}

.urunDetayTableSubtitle {
  font-size: 1.2rem;
  font-weight: 600;
  border-right: 1px solid white;
  margin-right: 0.5rem;
  padding-right: 0.5rem;
}

.urunDetayTableText {
  font-size: 1.2rem;
  font-weight: 500;
}

.urunDetayTableInfoWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.urunDetayTableCard {
  margin-bottom: 1rem;
}

.urunDetay404 {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 1.3rem;
}
.urunDetayArrow:hover {
  cursor: pointer;
}

@media (max-width: 992px) {
  .urunDetayTableCard {
    width: 90%;
  }
  .carouselWrapper {
    justify-content: center;
    margin-bottom: 1rem;
  }
  .urunDetayRelatedProducts {
    overflow-y: auto;
  }
  .urunDetayRelatedTitle {
    margin-left: 1rem;
  }
  .urunDetayTableWrapper {
    margin-left: 1rem;
  }
}

@media (max-width: 480px) {
  .urunDetayRelatedProducts {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .carouselWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 4rem;
    width: 100%;
  }

  .urunDetayCarousel {
    width: 90vw !important;
  }

  .urunDetayInfoWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .urunDetayTableCard {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .urunDetayImg {
    width: 100% !important;
    height: 500px;
    border-radius: 1rem;
  }

  .urunDetayTableTitle {
    padding: 1rem 0 0 1rem;
    margin-top: 1.3rem;
  }

  .urunDetayUrunSilButton {
    width: 10rem;
    margin-left: 1.2rem;
    height: 3rem;
  }

  .urunDetayRelatedWrapper {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
