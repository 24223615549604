.export_location {
  display: flex;
  flex-direction: column;
  background-color: #211c24;
  color: rgba(255, 255, 255, 0.9);
}

.export_location_image_info_wrapper {
  display: flex;
}

.export_location_image_info_wrapper > img {
  width: 50%;
}
.export_location_image_info_wrapper > div {
  padding: 1rem 13rem 0rem 5rem;
  letter-spacing: 0.07rem;
}

.export_location_image_info_wrapper > div > h3 {
  font-size: 2rem;
  border-bottom: 1.7px solid rgba(255, 255, 255, 0.9);
  padding-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
}
.export_location_image_info_wrapper > div > p {
  font-weight: 500;
}
.export_location_image_info_wrapper > div > h5 {
  font-size: 0.85rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}
.export_location_image_info_wrapper > div > button {
  margin-top: 3rem;
  padding: 0.3rem 1.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  border-radius: 0.3rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.9);
}
.export_location_image_info_wrapper > div > button:hover {
  cursor: pointer;
  background-color: #211c24;
  color: rgba(255, 255, 255, 0.9);
}
.export_location_image_info_wrapper > div > button:active {
  color: rgba(33, 28, 36);

  background-color: rgba(255, 255, 255, 0.9);
}

.export_location_bottom {
  display: flex;
}

.export_location_bottom > div {
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.9);
}
.export_location_bottom > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.export_location_bottom > div > div > svg {
  font-size: 3rem;
}
.export_location_bottom > div > div > h5 {
  font-size: 1.3rem;
  padding: 0.3rem 0;
  color: rgba(255, 255, 255, 0.9);
}
.export_location_bottom > div > div > p {
  font-size: 1.2rem;
  margin: 0;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

@media (max-width: 992px) {
  .export_location_image_info_wrapper {
    flex-direction: column;
  }

  .export_location_image_info_wrapper > img {
    width: 100%;
  }
  .export_location_image_info_wrapper > div {
    padding: 0.7rem;
    padding-bottom: 1rem;
    letter-spacing: 0.07rem;
  }

  .export_location_bottom {
    flex-direction: column;
  }

  .export_location_bottom > div {
    display: flex;
    flex: 1;
    justify-content: space-around;
    padding: 1rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.9);
  }
  .export_location_bottom > div > div {
    flex: 1;
    text-align: center;
  }

  .export_location_bottom > div > div > svg {
    font-size: 2.1rem;
  }
  .export_location_bottom > div > div > h5 {
    font-size: 1rem;
    margin: 0;
  }
  .export_location_bottom > div > div > p {
    font-size: 1rem;
  }
  .mobile_border_right {
    border-right: 1px solid rgba(255, 255, 255, 0.9);
  }
}

@media (max-width: 768px) {
  .export_location_image_info_wrapper {
    flex-direction: column;
  }

  .export_location_image_info_wrapper > img {
    width: 100%;
  }
  .export_location_image_info_wrapper > div {
    padding: 0.7rem;
    padding-bottom: 1rem;
    letter-spacing: 0.07rem;
  }

  .export_location_bottom {
    flex-direction: column;
  }

  .export_location_bottom > div {
    display: flex;
    flex: 1;
    justify-content: space-around;
    padding: 1rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.9);
  }
  .export_location_bottom > div > div {
    flex: 1;
    text-align: center;
  }

  .export_location_bottom > div > div > svg {
    font-size: 2.1rem;
  }
  .export_location_bottom > div > div > h5 {
    font-size: 1rem;
    margin: 0;
  }
  .export_location_bottom > div > div > p {
    font-size: 1rem;
  }
  .mobile_border_right {
    border-right: 1px solid rgba(255, 255, 255, 0.9);
  }
}
