/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.headerContainer {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.headerContainerExtra {
  background-color: #211c24;
  padding: 1rem 0rem;
}

.headerLogoWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.headerLogoWrapper {
  cursor: pointer;
}

.headerLogoWrapper > div {
  color: #f6f6f6;
  font-size: 1.5rem;
  margin-bottom: .7rem;
  margin-left: 1rem;
}

.headerInnerContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  -webkit-box-align: center;

  -ms-flex-align: center;

  align-items: center;
}

.headerLogoutButton {
  margin-right: 1rem;
}

.HeaderBurgerNavBar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: #211c24;
  width: 100%;
  z-index: 100;
  padding: 20px;
}

.headerLogoImg {
  width: 3rem;
  height: 2.5rem;
  -webkit-transition: all ease 0.21s;
  -o-transition: all ease 0.21s;
  transition: all ease 0.21s;
}

.headerLogoImg:hover {
  cursor: pointer;
  opacity: 0.9;
}

.headerTelephone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 4rem;
  margin-top: 0.6rem;
  color: #f6f6f6;
}

.headerTelephone:hover {
  cursor: pointer;
}

.headerTelephoneText {
  font-size: 1rem;
  margin-left: 0.4rem;
}

@media (max-width: 480px) {
  .headerTelephone {
    margin-right: 1rem;
  }
  .headerLogoWrapper > div {
    color: #f6f6f6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
  }
}
