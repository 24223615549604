.language__selector__wrapper {
  margin-left: 1rem;
  z-index: 35000;
}

.language__select > div {
  background-color: transparent !important;
  border: none !important;
}

.flag__wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
}

.flag {
  width: 3.5rem;
  height: 1rem;
  padding: 0 0.5rem;
}
