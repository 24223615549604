/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.whatsappButton {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
 
   z-index: 100;
}



.whatsappButton:hover {
  cursor: pointer;
  opacity: 0.95;
 -webkit-transform: scale(1.01);
     -ms-transform: scale(1.01);
         transform: scale(1.01);
}

.whatsappLogoFooter:hover {
  cursor: pointer;
}

@media (max-width: 480px) {
  .whatsappButton {
    bottom: 1rem;
    right: 1rem;
    width: 2.5rem;
  height: 2.5rem;
  }
  
}