/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.relatedProductWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.relatedProductImg {
  width: 16rem;
  height: 19rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom: 1px solid #211c24;
}

.relatedProductTitle {
  margin: 1rem 0rem;
  font-weight: 600;
  font-size: 1.1rem;
}

.relatedProductCard div {
  padding: 0 !important;
}

.relatedProductCard {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  border-radius: 1rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.relatedProductCard:hover {
  cursor: pointer;
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

@media (max-width: 992px) {
  .relatedProductCard {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .relatedProductCard {
    margin-bottom: 1rem;
  }
  .relatedProductImg {
    width: 100%;
    height: 20rem;
  }
}
