.card5 {
  margin: 3rem 0rem;
}
.card5_container {
  display: flex;
  justify-content: center;
}

.card5_card {
  width: 240px;
  margin: 0 1rem;
  border: 1px solid #211c24;
  border-radius: 0.3rem;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease;
}

.card5_card:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.card5_image {
  border-bottom: 1px solid #211c24;
  width: 100%;
  height: 270px;
}

@media (max-width: 992px) {
  .card5_container {
    width: 100%;
    overflow-x: scroll;
    overflow-wrap: break-word;
    justify-content: flex-start;
    padding-bottom: 1rem;
  }

  .card5_card {
    margin: 0 0.5rem;
  }
  .card5_image {
    width: 240px;
  }
}

@media (max-width: 768px) {
  .card5_container {
    width: 100%;
    overflow-x: scroll;
    overflow-wrap: break-word;
    justify-content: flex-start;
  }

  .card5_card {
    margin: 0 0.5rem;
  }
  .card5_image {
    width: 180px;
  }
}
